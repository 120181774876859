import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import LayoutNavBar from "../LayoutNavBar/LayoutNavBar";
import SideMenuBar from "./SideMenu/SideMenuBar";

function Home() {
  const [showSideMenu, setShowSideMenu] = useState(true);

  const toggleMenu = () => {
    setShowSideMenu((flag) => !flag);
  };
  useEffect(() => { }, [showSideMenu]);
  return (
    <>
      <LayoutNavBar />
      <div className={`main-body ${showSideMenu ? "showMenu" : ""}`}>
        <div className="container-fluid h-100">
          <div className="row h-100 page-body">
            <div
              className={`col-md-2 sidemenu-container animate__animated ${showSideMenu ? "animate__fadeInLeft" : "fadeOutTransition"
                }`}
            >
              <SideMenuBar />
            </div>
            <div
              className={`col-md-${showSideMenu ? 10 : 12
                } page-bdoy animate__animated h-100 animate__fadeInRight ${showSideMenu ? "" : "animate__fadeInRight"
                }`}
            >
              {/* <div className="sidemenu-toggle-btn">
                <button className="btn btn-sm btn-primary" onClick={toggleMenu}>
                  <i
                    className={`bi bi-chevron-${showSideMenu ? "left" : "right"
                      }`}
                  ></i>
                </button>
              </div> */}
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
