import React, { useEffect, useRef } from "react";

function PromptBox({ dataObj = {}, handleDataChange, onSendButtonClick }) {
  const textareaRef = useRef();

  const resizeTextarea = () => {
    const refObj = textareaRef.current;
    if (refObj) {
      refObj.style.height = "auto";
      refObj.style.height = `${refObj.scrollHeight - 30}px`;
    }
  };

  useEffect(() => {
    const resizeEvent = textareaRef.current?.addEventListener(
      "input",
      resizeTextarea
    );
    return () => {
      if (resizeEvent) {
        textareaRef.current?.removeEventListener("input", resizeTextarea);
      }
    };
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      onSendButtonClick();
    }
  };

  useEffect(() => {
    // textareaRef.current?.focus();
  }, [dataObj.text]);

  return (
    <>
      <textarea
        ref={textareaRef}
        onKeyDown={handleKeyPress}
        className="defaultMessageBox"
        placeholder="How can I help you?"
        value={dataObj.text}
        onChange={(e) => handleDataChange(e.target.value)}
      />
    </>
  );
}

export default PromptBox;
