

import React, { useState, useEffect } from 'react';
import './TypeWriter.scss'; // Import the CSS file for cursor styles

const TypeWriter = ({ text, speed = 30, className }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < text.length) {
      const timeout = setTimeout(() => {
        setDisplayedText(prev => prev + text[index]);
        setIndex(prev => prev + 1);
      }, speed);

      return () => clearTimeout(timeout);
    }
  }, [index, text, speed]);

  return (
    <div className={`typewriting ${className}`}>
      {displayedText}
      {
        text.length > displayedText.length && <span className="cursor">|</span>
      }
    </div>
  );
};

export default TypeWriter;

