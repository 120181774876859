export const SideMenuList = [
  {
    title: "Profile",
    path: "profile",
  },
  {
    title: "Ask AI",
    path: "bot",
  },
];

export default SideMenuList;
