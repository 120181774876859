import Utils from "./Utils";

import EndPoints, {
  API_BASE_URL,
  LOCAL_BASE_URL,
  BASE_MAPPING,
} from "./config";

const apiOptions = (
  endPointDetails,
  payload = null,
  options = { type: "json" }
) => {
  try {
    let defaultHeaders = {};
    let queryParams = "";

    let { endpointName, endpointKey, extendedUrl, additionalHeaders } =
      endPointDetails;

    let endpoint = EndPoints[endpointKey][endpointName].endpoint;
    let baseURL = "";
    if (
      !Utils.isHyperLink(endpoint) &&
      !Utils.isHyperLink(BASE_MAPPING[endpointKey])
    ) {
      baseURL = API_BASE_URL;
    }
    let method = EndPoints[endpointKey][endpointName].method;
    extendedUrl = extendedUrl ? `${extendedUrl}` : "";

    let isJSON = EndPoints[endpointKey][endpointName].json;

    if (isJSON) {
      baseURL = LOCAL_BASE_URL;
      endpoint = `${endpointName}.json`;
      method = "GET";
    }

    const userInfo = Utils.getStorage("userInfo") || {};
    defaultHeaders["Authorization"] = "Basic dXNlcjoxMjM0";
    defaultHeaders["tenant-name"] = "auth";
    defaultHeaders["user-id"] = "web";
    defaultHeaders["tenant-id"] = userInfo.tenantId;

    defaultHeaders = { ...defaultHeaders, ...additionalHeaders };

    const requestOptions = {
      method,
      headers: options.headers || defaultHeaders,
    };

    let body;
    if (options.type === "json") {
      if (method === "GET") {
        queryParams = payload ? `?${Utils.objectToQueryParams(payload)}` : "";
      } else {
        body = payload ? JSON.stringify(payload) : null;
        requestOptions.body = body;
      }
      defaultHeaders["Content-Type"] = "application/json";
    } else {
      body = payload;
      requestOptions.body = body;
    }

    let apiMapping = isJSON ? "" : BASE_MAPPING[endpointKey];

    let finalUrl = `${apiMapping}${endpoint}${extendedUrl}${queryParams}`;
    finalUrl = `${baseURL}${finalUrl}`;
    return { finalUrl, requestOptions };
  } catch (error) {
    throw error;
  }
};

export default apiOptions;
