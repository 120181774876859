import React, { useState } from "react";
import "./ProjectsInfo.scss";
import ProjectCard from "./ProjectCard";

function ProjectsInfo({ data = [] }) {
  const [count, setCount] = useState(1);

  const loadMoreItems = () => {
    setCount((prevVisibleItems) => prevVisibleItems + 1);
  };

  return (
    <div className="row text-center">
      <div className="col-md-12 form-group">
        {data.slice(0, count).map((item, index) => (
          <div key={index}>
            <ProjectCard data={item} />
          </div>
        ))}
      </div>
      <div className="col-md-12">
        {count < data.length ? (
          <span className="see-more" onClick={loadMoreItems}>
            See More...
          </span>
        ) : (
          "For More Projects, please download the CV, will be added here soon..."
        )}
      </div>
    </div>
  );
}

export default ProjectsInfo;
