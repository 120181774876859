import React, { useState } from "react";
import "./CardsSlider.scss";

const s3Path = "/assets";
const CardsSlider = ({ cardsData = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(1);

  const updateCards = (index) => {
    if (index >= 0 && index < cardsData.length) {
      setCurrentIndex(index);
    }
  };

  return (
    <div className="cards-slider-container">
      <div className="card-slider">
        {cardsData.map((card, index) => (
          <div
            key={index}
            className={`card ${
              index === currentIndex - 1
                ? "prev-card"
                : index === currentIndex
                ? "active-card"
                : index === currentIndex + 1
                ? "next-card"
                : ""
            }`}
          >
            <div className="experience-card">
              <div className="card-header bg-light text-center">
                <img
                  src={`${s3Path}/images/companies/${card.image}`}
                  alt="Company Logo"
                  className="img-fluid"
                  style={{ maxHeight: "100px" }}
                />
              </div>
              <div className="card-body">
                <div className="company-desc">
                  <h4 className="card-title">{card.companyName}</h4>
                  <p className="card-text">
                    <strong>Description:</strong> {card.description}
                  </p>
                  <p className="card-text">
                    <strong>Designation:</strong> {card.designation}
                  </p>
                  <p className="card-text">
                    <strong>Tenure:</strong> {card.tenure}
                  </p>
                  <p className="card-text">
                    <strong>Tech Stacks:</strong> {card.techStacks.join(", ")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}

        {currentIndex > 0 && (
          <button
            className="nav-button prev-button"
            onClick={() => updateCards(currentIndex - 1)}
          >
            <i className="bi bi-chevron-double-left"></i>
          </button>
        )}

        {currentIndex < cardsData.length - 1 && (
          <button
            className="nav-button next-button"
            onClick={() => updateCards(currentIndex + 1)}
          >
            <i className="bi bi-chevron-double-right"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default CardsSlider;
