export const LOCAL_BASE_URL = "/assets/jsons/";

const { REACT_APP_API_URL } = process.env;

export const API_BASE_URL = REACT_APP_API_URL || '';
//
const EndPoints = {
  default: {
    usersList: { json: true, endpoint: "/v1/tenants/usersList", method: "GET" },
    defaultText: {
      json: true,
      endpoint: "",
      method: "GET",
    },
    uiConfig: {
      json: true,
      endpoint: "",
      method: "GET",
    },
    profileData: {
      json: true,
      endpoint: "",
      method: "GET",
    },
  },
  AI_SERVICES: {
    infer: { endpoint: "infer", method: "POST" },
    promptQuery: { endpoint: "chat", method: "POST" },
    fileUpload: { endpoint: "upload", method: "POST" },
    addVisitor: { endpoint: "addVisitor", method: "POST" },
  },

};

export const BASE_MAPPING = {
  default: "",
  AI_SERVICES: `/`,
};

export default EndPoints;
