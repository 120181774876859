import React from 'react'
import ChatBot from '../chat-bot/ChatBot'

function AIBot() {
  return (
    <div className='ai-bot profile-wrapper '>
        <ChatBot />
    </div>
  )
}

export default AIBot