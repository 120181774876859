import React, { useState, useEffect } from 'react'
import API_Call from '../../../services/apiCall'
import Faqs from './FAQs/Faqs';

function Profile() {
    const [profileData, setProfileData] = useState(null);
    const faqsMapping = [
        {key: 'FAQ_Web', title: 'Web Engineering'},
        {key: 'FAQ_SE', title: 'Software Engineering'},
        {key: 'FAQ_Academics', title: 'Academics'},
    ]
    const {
        response: profileDataResponse,
        error: profileDataError,
        fetchData: fetchProfileData,
    } = API_Call({ endpoint: "profileData" });

    const onLoadFetch = () => {
        fetchProfileData({});
    };
    useEffect(() => {
        if (profileDataResponse && profileDataResponse.status === "success") {
            setProfileData(profileDataResponse.data);
        }
      }, [profileDataResponse]);
    useEffect(() => {
        onLoadFetch({});
    }, []);

    useEffect(()=>{
        console.log(profileData)
    }, [profileData])
    return (
       <div>
        {
            profileData && <div>
                {/* Faqs */}
                <div className="mb-2" id="faqs_id">
                   {
                    faqsMapping.map(ele=> 
                        <div className="mt-2" key={`faq_${ele.key}`}>
                            <Faqs  id={ele.key} data={profileData[ele.key]} title={ele.title}/>
                    </div>
                    )
                   } 
                </div>
            </div>
        }
       </div>
    )
}

export default Profile