import React, { useState } from "react";
import SideMenuList from "./SideMenuList";
import "./SideMenu.scss";
import { NavLink } from "react-router-dom";

function SideMenuBar() {
  const [list, setList] = useState(SideMenuList);
  const toggleOpen = (index) => {
    const tempList = [...list];
    tempList[index].open = !tempList[index].open;
    setList(tempList);
  };
  return (
    <>
      <div className="sideMenu">
        <ul className="list-group">
          {list.map((ele, index) => {
            return ele.subMenu ? (
              <li key={ele.path} className="list-group-item">
                <div onClick={() => toggleOpen(index)}>
                  {ele.title}
                  <span className="right-align">
                    {!ele.open ? (
                      <i className="bi bi-chevron-down"></i>
                    ) : (
                      <i className="bi bi-chevron-up"></i>
                    )}
                  </span>
                </div>
                {ele.open && (
                  <ul className="nav-submenu">
                    {ele.subMenu.map((subMenu) => (
                      <li key={subMenu.path} className="nav-submenu-item">
                        <NavLink
                          className={({ isActive }) =>
                            `${isActive ? "active" : "none"}`
                          }
                          to={`${subMenu.path}`}
                        >
                          {subMenu.title}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ) : (
              <li key={ele.path} className="list-group-item">
                <NavLink
                  className={({ isActive }) =>
                    `nav-link ${isActive ? "active" : "none"}`
                  }
                  to={`${ele.path}`}
                >
                  {ele.title}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default SideMenuBar;
