import React from 'react'
import Utils from '../../services/Utils';
import { motion } from 'framer-motion';

const AnimatedText = ({ text }) => {
    const parsedHtml = Utils.parseMarkdownToHtml(text);

    const variants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
    };

    return (
        <motion.div
            initial="hidden"
            animate="visible"
            variants={variants}
            dangerouslySetInnerHTML={{ __html: parsedHtml }}
        />
    );
};

export default AnimatedText