import React from "react";

const ProjectCard = ({ data }) => {
  return (
    <div className="project-card">
      <div className="container mt-5">
        <div className="card p-4 shadow-lg">
          <h2 className="text-primary">{data.projectName}</h2>
          <p className="lead">{data.description}</p>
          {data.details.map((detail, index) => (
            <p key={index}>{detail}</p>
          ))}
          <p className="highlight">
            <strong>Role:</strong> {data.role.title}
          </p>
          <ul className="list-unstyled">
            {data.role.responsibilities.map((responsibility, index) => (
              <li key={index}>- {responsibility}</li>
            ))}
          </ul>
          <p>
            <strong>Tech Stack:</strong> {data.techStack}
          </p>
          {data.keyComponent && (
            <p>
              <strong>Key Component Built:</strong> {data.keyComponent}
            </p>
          )}
          {data.urls &&
            data.urls.map((ele) => (
              <a href={ele} key={ele} target="_blank">
                {ele}
              </a>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
