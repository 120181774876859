import React, { useEffect, useRef, useState } from "react";
import "./AudioPitchAnimation.scss";
import DataToBars from "./DataToBars";

function AudioPitchAnimation({ dotStyles, isRecording, stream }) {
  const animationFrameIdRef = useRef(null);
  const audioContextRef = useRef(null);
  const [data, setData] = useState([]);
  const dataToBarsRef = useRef();

  const [currentDotStyles, setCurrentDotStyles] = useState({
    background: "black",
    width: 3,
    height: 3,
    borderRadius: 2,
    marginRight: 2,
  });

  useEffect(() => {
    setCurrentDotStyles((data) => {
      return { ...data, dotStyles };
    });
  }, [dotStyles]);

  function reduceByAggregation(arr, newSize) {
    let reducedArray = [];
    if (newSize < arr.length) {
      for (let i = 0; i < newSize; i++) {
        const chunkSize = Math.floor(arr.length / newSize);
        const chunk = arr.slice(i * chunkSize, (i + 1) * chunkSize);
        const sum = chunk.reduce((a, b) => a + b, 0);
        const eachVal = sum === 0 ? 0 : sum / chunk.length;
        let currentChunk = (eachVal / 256) * 100;
        currentChunk = Math.ceil(currentChunk / 3);
        reducedArray.push(currentChunk);
      }
    } else {
      for (let i = 0; i < arr.length; i++) {
        let currentChunk = (arr[i] / 256) * 100;
        currentChunk = Math.ceil(currentChunk / 3);
        reducedArray.push(currentChunk);
      }
    }
    return reducedArray;
  }

  const startAnalyzing = (audioContext, source) => {
    try {
      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 2048; // Adjust the FFT size for better resolution
      source.connect(analyser);

      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      function analyze() {
        try {
          analyser.getByteFrequencyData(dataArray);
          const frequencyData = Array.from(dataArray);
          const widthOfDot =
            currentDotStyles.width + currentDotStyles.marginRight;
          const width = Math.ceil(
            dataToBarsRef.current.offsetWidth / widthOfDot
          );
          const reducedData = reduceByAggregation(frequencyData, width);
          setData(reducedData);
          animationFrameIdRef.current = requestAnimationFrame(analyze);
        } catch (error) {
          console.log("Exception Error", error);
        }
      }
      analyze();
    } catch (error) {
      console.log("Exception Error", error);
    }
  };

  useEffect(() => {
    if (stream) {
      try {
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        audioContextRef.current = audioContext;
        const source = audioContext.createMediaStreamSource(stream);
        startAnalyzing(audioContext, source);
      } catch (error) {
        console.error("unsupported stream format", error);
        clearRefs();
      }
    } else {
      console.log("pass the stream");
      clearRefs();
    }
    return () => {
      clearRefs();
    };
  }, [stream]);

  useEffect(() => {
    if (!isRecording) {
      clearRefs();
    }
  }, [isRecording]);

  const clearRefs = () => {
    try {
      if (animationFrameIdRef.current) {
        cancelAnimationFrame(animationFrameIdRef.current);
        // clearTimeout(animationFrameIdRef.current);
      }
      if (
        audioContextRef.current &&
        audioContextRef.current.state !== "closed"
      ) {
        audioContextRef.current.close();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div ref={dataToBarsRef} className="bars-container">
      <DataToBars data={data} currentDotStyles={currentDotStyles} />
    </div>
  );
}

export default AudioPitchAnimation;
