import React, { useEffect, useState } from "react";
import Select from "react-select";

const CustomSelect = ({
  options = [],
  onOptionChange,
  value = "",
  config = {},
  selectAll = false,
  isDisabled = false,
}) => {
  const [selectedOption, setSelectedOption] = useState();
  const [ddOptions, setDDOptions] = useState([]);

  useEffect(() => {
    let selectAllOptions = [];
    if (selectAll) {
      selectAllOptions.push({ value: "ALL", label: "ALL" });
    }
    let currentOptions = [...selectAllOptions, ...options];
    setDDOptions(currentOptions);
    const defaultOtpion = currentOptions.filter((ele) => ele.value === value);
    if (defaultOtpion.length) {
      changeSelectedOption(defaultOtpion[0]);
    }
    if (
      (value === undefined || value === "" || value === null) &&
      currentOptions.length
    ) {
      changeSelectedOption(currentOptions[0]);
    }
  }, [value, options, selectAll]);

  const changeSelectedOption = (option) => {
    if (!selectedOption || option.value !== selectedOption.value) {
      setSelectedOption(option);
      if (option && option.value) {
        onOptionChange(option.value);
      }
    }
  };

  return (
    <Select
      value={selectedOption}
      onChange={changeSelectedOption}
      options={ddOptions}
      isDisabled={isDisabled}
    />
  );
};

export default CustomSelect;
