import React, { useEffect, useState } from 'react';
import { FileUploader } from "react-drag-drop-files";
import API_Call from '../../../services/apiCall';

function UploadResume() {
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };
  const {
    response: fileUploadResponse,
    error: fileUploadError,
    fetchData: fileUpload,
  } = API_Call({ endpoint: "fileUpload", endpointKey: 'AI_SERVICES', options: { type: 'formData' } });

  useEffect(() => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      console.log(formData)
      fileUpload({ payload: formData })
    }
  }, [file])

  useEffect(() => {
    if (fileUploadResponse && fileUploadResponse.status === 'success') {
      alert('file uploaded successfully')
    }
  }, [fileUploadResponse])

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <FileUploader handleChange={handleChange} name="file" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default UploadResume