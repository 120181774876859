export const Utils = {
  setStorage: (key, value) => {
    if (value) {
      value = JSON.stringify(value);
      localStorage.setItem(key, value);
    } else {
      localStorage.setItem(key, value);
    }
  },
  removeStorage: (key) => {
    localStorage.removeItem(key);
  },
  getStorage: (key) => {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  },
  clearStorage: () => {
    localStorage.clear();
  },
  objectToQueryParams: (obj = {}) => {
    return Object.keys(obj)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
      )
      .join("&");
  },
  isHyperLink: (inputString = "") => {
    var urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return urlPattern.test(inputString);
  },
  getTenantId: () => {
    let tenantId = "";
    const userInfo = Utils.getStorage("userInfo");
    if (userInfo) {
      tenantId = userInfo.tenantId;
    }
    return tenantId;
  },
  parseMarkdownToHtml: (text) => {
    // Replace headers marked with ##
    text = text.replace(/## (.+)/g, '<h2>$1</h2>');

    // Replace bold text marked with **
    text = text.replace(/\*\*(.+?)\*\*/g, '<strong>$1</strong>');

    // Replace italic text marked with *
    text = text.replace(/\*(.+?)\*/g, '<em>$1</em>');

    // Replace links marked with [text](url)
    text = text.replace(/\[(.+?)\]\((.+?)\)/g, '<a href="$2">$1</a>');

    return text;
  },
  formValidation: {
    text: (input = "") => {
      return input.trim().length > 1;
    },
    email: (input = "") => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(input);
    },
    name: (input = "") => {
      const nameRegex = /^[A-Za-z]+(?:[ -][A-Za-z]+)*$/;
      return nameRegex.test(input);
    },
    password: (input = "") => {
      return input.length > 3;
    },
    json: (input) => {
      try {
        const jsonObj = JSON.parse(input.trim());
        if (typeof jsonObj === "object") {
          return true;
        }
        return false;
      } catch (error) {
        return false;
      }
    },
    url: (input = "") => {
      const nameRegex = /^(https?|ftp):\/\/([^\s$.?#].[^\s]*)$/;
      return nameRegex.test(input);
    },
    array: (data) => {
      if (data && data.length > 0) {
        return true;
      }
      return false;
    },
    number: (input) => {
      const regex = /^\d+$/;
      return regex.test(input);
    },
  },
  validForm: (formRef) => {
    let flag = true;
    let fields = {};
    Array.from(formRef.current.elements).forEach((input) => {
      const validationData = Utils.validateFormField({ input, flag, fields });
      flag = validationData.flag;
      fields = validationData.fields;
    });
    Utils.scrollToFirsErrorEle();
    return { flag, fields };
  },
  validateFormField: ({ input, flag, fields }, nonNative = false) => {
    let type;
    try {
      type = input.getAttribute("validatortype");
    } catch { }
    if (type) {
      if (!Utils.formValidation[type](input.value) && !input.disabled) {
        const errorText = `Enter Valid ${input.getAttribute("label")}`;
        fields[input.name] = errorText;
        flag = false;
        if (!nonNative) {
          const errorDiv = document.createElement("p");
          errorDiv.textContent = errorText;
          errorDiv.setAttribute("error-div", "error");
          errorDiv.className = "error-field";
          const existingSibling = input.nextElementSibling;
          if (!existingSibling?.hasAttribute("error-div")) {
            input.insertAdjacentElement("afterend", errorDiv);
          }
          input.classList.add("error");
        }
      } else {
        fields[input.name] = null;
        if (!nonNative) {
          const errorDiv = input.nextElementSibling;
          if (errorDiv && errorDiv.hasAttribute("error-div")) {
            errorDiv.remove();
          }
          input.classList.remove("error");
        }
      }
    }
    return { input, flag, fields };
  },
  scrollToFirsErrorEle: () => {
    setTimeout(() => {
      var firstErrorElement = document.querySelector(".error-field");
      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 300);
  },
  copyToClipboard: (text, callBack) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard successfully:", text);
        callBack("Text copied to clipboard successfully");
      })
      .catch((err) => {
        console.error("Unable to copy text to clipboard:", err);
        callBack("Unable to copy text to clipboard");
      });
  },
  audioBlobToBase64: (audioBlob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(audioBlob);
    });
  },
  textValidate: (data) => {
    if (Utils.isHyperLink(data)) {
      return (
        <a href={data} data-bs-toggle="tooltip" title={data}>
          {data}
        </a>
      );
    } else if (typeof data === "boolean") {
      return data ? "True" : "False";
    }
    return data;
  },
  debounce: (fn, time) => {
    let timer;
    return (...args) => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        fn(args);
      }, [time]);
    };
  },
  formatText: (text = "") => {
    let string = "";
    try {
      text = text.replaceAll("<", "&lt;");
      text = text.replaceAll("/>", "&gt;");
      string = text
        .trim()
        .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
        .replaceAll("*", "•");
    } catch (error) {
      console.log(error);
    }
    return string;
  },
  isValidDate: (date) => {
    const parsedDate = new Date(date);
    return !isNaN(parsedDate);
  },
};
export default Utils;
