import React, { useEffect, useRef, useState } from "react";
import API_Call from "../../../services/apiCall";
import AudioPlayer from "../../../common-components/audio-player/AudioPlayer";
import StreamingAudioPlayer from "../../../common-components/audio-stream/StreamingAudioPlayer";
import { useRootContext } from "../../../context/context";
import UploadResume from "../Profile/UploadResume";
import TypeWriter from "../../../common-components/type-writer/TypeWriter";
import Utils from "../../../services/Utils";
import AnimatedText from "../../../common-components/animated-text/AnimatedText";

const welcomeText = `Feel free to ask anything about Yaseen's profession.`;
const welcomText2 = `You can upload a profile of any developer and compare I'll assist you.`;

const ChatInteractions = ({
  addNewChat,
  handleDataChange,
  botId,
  sessionDetails = {},
}) => {
  const [chatData, setChatData] = useState([]);
  const containerRef = useRef(null);
  const messagesEndRef = useRef(null);
  const [modalData, setModalData] = useState(null);
  const { uiConfig } = useRootContext();
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (addNewChat) {
      setChatData((data) => [...data, addNewChat]);
    }
  }, [addNewChat]);

  // Function to scroll to the bottom of the div
  const scrollToBottom = () => {
    if (containerRef.current && !touched) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  const handleUserInteraction = () => {
    debugger
    setTouched(true);
  };

  const stopAutoScroll = () => {
    const currentDiv = containerRef.current;
    if (currentDiv) {
      currentDiv.addEventListener("scroll", handleUserInteraction);
      currentDiv.addEventListener("click", handleUserInteraction);
    }
  };

  useEffect(() => {
    const currentDiv = containerRef.current;

    const observer = new MutationObserver(scrollToBottom);
    const config = {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ["style", "class"],
      characterData: true,
    };

    if (currentDiv) {
      observer.observe(currentDiv, config);
    }

    scrollToBottom();

    window.addEventListener("resize", scrollToBottom);


    return () => {
      if (currentDiv) {
        observer.disconnect();
      }
      stopAutoScroll();
      window.removeEventListener("resize", scrollToBottom);
    };
  }, []);

  const {
    response: chatsResponse,
    loading: chatsLoading,
    error: chatsError,
    fetchData: fetchChats,
  } = API_Call({ endpoint: "fetchChatInteractions", endpointKey: "BOT_LAYER" });

  useEffect(() => {
    if (uiConfig.chatHistory) {
      fetchHistory();
    }
  }, []);

  const fetchHistory = () => {
    fetchChats({
      extendedUrl: `/${botId}/messages`,
      payload: { chatId: sessionDetails.chatId },
    });
  };

  useEffect(() => {
    if (
      chatsResponse &&
      chatsResponse.status === "success" &&
      chatsResponse.data
    ) {
      setChatData(chatsResponse.data.messages);
      setTouched(false);
    }
  }, [chatsResponse]);

  useEffect(() => { }, [modalData]);

  function sendPrompt(value) {
    handleDataChange(value);
  }
  const playText = (obj, index) => {
    const tempChatData = [...chatData];
    tempChatData[index].processAudio = true;
    setChatData(tempChatData);
  };

  const getAudioInputText = (obj) => {
    let input_text = null;
    if (obj.type === "widget") {
      input_text = obj.text?.message;
    } else {
      input_text = obj.text;
    }
    let payload = {
      input_language: "en",
      input_speaker: "female",
      input_text,
    };
    return payload;
  };

  const displayAudioBtn = (obj) => {
    return false;
    let flag;
    if (obj.type === "widget" && obj.text?.message) {
      flag = true;
    } else if (obj.type === "text" && obj.text) {
      flag = true;
    } else {
      return false;
    }
    if (obj.audioData) {
      return false;
    }
    if (obj.processAudio) {
      return false;
    }
    if (!obj.fromPrompt) {
      return false;
    }
    return flag;
  };

  return (
    <div className="conversation-container" ref={containerRef}>
      {chatData && chatData.length === 0 && (
        <>
          <h5 className="intro-text">
            <TypeWriter text={welcomeText} />
          </h5>
          <h5>{welcomText2}</h5>
          <UploadResume />
        </>
      )}
      {chatData &&
        chatData.map((ele, index) => (
          <div
            key={ele.timeStamp}
            className={`${ele.error ? "error-prompt" : ""} message`}
          >
            {/* ICON */}
            {ele.fromPrompt ? (
              <div className="prompt-icon">
                {/* keep a user icon herer */}
                {ele.debug && (
                  <button
                    className="btn btn-sm btn-plain"
                    onClick={() => setModalData(ele)}
                  >
                    <i className="bi bi-bug info-icon"></i>
                  </button>
                )}
              </div>
            ) : (
              <div className="user-icon">
                <img src="/assets/images/Avatar.svg" alt="logo" />
              </div>
            )}
            <div className={`${ele.fromPrompt ? "left" : "right"}`}>
              <div className="message-content">
                {ele.type === "text" ?
                  <AnimatedText text={ele.text} />
                  : ""}
                <span className="message-time">
                  {ele.audioData && (
                    <AudioPlayer src={ele.audioData} autoplay={true} />
                  )}
                  {ele.processAudio && (
                    <StreamingAudioPlayer
                      endpoint="infer"
                      endpointKey="TTS_SERVICE"
                      payload={getAudioInputText(ele)}
                    />
                  )}
                  {displayAudioBtn(ele) && (
                    <button
                      className="btn btn-sm btn-plain"
                      onClick={() => playText(ele, index)}
                      disabled={ele.loadingAudio}
                    >
                      <i className="bi bi-megaphone"></i>
                    </button>
                  )}
                </span>
              </div>
            </div>
          </div>
        ))}
      <div ref={messagesEndRef}></div>
    </div>
  );
};

export default ChatInteractions;
