import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const InfiniteItems = ({ items = [] }) => {
  const [visibleItems, setVisibleItems] = useState(9);

  const loadMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 9);
  };

  return (
    <div className="container mt-4">
      <div className="row">
        {items.slice(0, visibleItems).map((item, index) => (
          <div key={index} className="col-md-4 mb-4">
            <div className="skills-card">
              <div className="skill-icon mb-3">
                <i className="fas fa-code fa-3x"></i>
              </div>
              <h4>{item.techName}</h4>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
        <div className="col-md-12">
          {visibleItems < items.length && (
            <span className="see-more" onClick={loadMoreItems}>
              See More...
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfiniteItems;
