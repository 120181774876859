import { useEffect, useState } from "react";
import apiService from "./apiService";
import { useRootContext } from "../context/context";
import { toast } from "react-toastify";

const API_Call = ({ endpoint, options, endpointKey = "default" }) => {
  const [loading, setLoading] = useState(null);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const { setShowLoader } = useRootContext();
  const [skipLoader, setSkipLoader] = useState(null);

  useEffect(() => {
    if (skipLoader === false) {
      if (loading === false) {
        setShowLoader((count) => count - 1);
      } else if (loading === true) {
        setShowLoader((count) => count + 1);
      }
    }
  }, [loading, skipLoader, setShowLoader]);

  const displayMessage = ({ type, message, hide = false }) => {
    if (message && !hide) {
      toast(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "dark",
      });
    }
  };

  const fetchData = ({
    payload,
    hideLoader = false,
    hideToaster = false,
    extendedUrl = "",
    additionalHeaders = {},
  }) => {
    setSkipLoader(hideLoader);
    setLoading(true);
    try {
      apiService(
        { endpointName: endpoint, endpointKey, extendedUrl, additionalHeaders },
        payload,
        options
      )
        .then(
          (response) => {
            if (response.ok) {
              setResponse(response.data);
              displayMessage({
                type: "success",
                message: response.data.message,
                hide: hideToaster,
              });
            } else {
              setError(response);
              displayMessage({
                type: "failed",
                message: response.message,
                hide: hideToaster,
              });
              console.error(response);
            }
          },
          (err) => {
            console.log(err);
          }
        )
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setError("API Error");
      displayMessage({
        type: "success",
        message: "API Error",
        hide: hideToaster,
      });
      setLoading(false);
    }
  };
  return { response, loading, error, fetchData };
};

export default API_Call;
