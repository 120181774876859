import React, { useEffect, useRef, useState } from "react";
import "./ChatBot.scss";
import API_Call from "../../../services/apiCall";
import UseAudioRecorder from "../../../common-components/webRtc/UseAudioRecorder";
import ChatInteractions from "./ChatInteractions";
import Lottie from "lottie-react";
import LottieLoader from "../../../common-components/lottie-files/loading.json";
import AudioPitchAnimation from "../../../common-components/audio-pitch-animation/AudioPitchAnimation";
import Utils from "../../../services/Utils";
import { useParams } from "react-router-dom";
import PromptBox from "./PromptBox";

function ChatBot({ displayHeader = true }) {
  const initialData = () => {
    return {
      type: "text",
      text: "",
    };
  };
  const [dataObj, setDataObj] = useState(initialData());
  const [addNewChat, setNewChatData] = useState(null);
  const [showChatLoader, setChatLoader] = useState(false);
  const [showRecordingHTML, setShowRecordingHTML] = useState(false);
  const [sendAudio, setSendAudio] = useState(false);
  const params = useParams();

  const {
    response: speechResponse,
    loading: speechLoading,
    error: speechError,
    fetchData: processSpeech,
  } = API_Call({ endpoint: "speechToText", endpointKey: "BOT_LAYER" });

  const {
    isRecording,
    audioURL,
    startRecording,
    stopRecording,
    blob,
    webRTCSupport,
    autoStopRecord,
    stream,
  } = UseAudioRecorder();

  const {
    response: queryResponse,
    loading: queryLoading,
    error: queryError,
    fetchData: processQuery,
  } = API_Call({ endpoint: "promptQuery", endpointKey: "AI_SERVICES" });

  useEffect(() => {
    if (queryLoading || speechLoading) {
      setChatLoader(true);
    } else {
      setChatLoader(false);
    }
  }, [queryLoading, speechLoading]);

  useEffect(() => {
    if (isRecording || autoStopRecord) {
      setShowRecordingHTML(true);
    } else {
      setShowRecordingHTML(false);
    }
  }, [isRecording, autoStopRecord]);

  const handleSubmit = () => {
    if (dataObj.type === "text") {
      let payload = {
        prompt: dataObj.text,
      };
      processQuery({
        payload,
        hideLoader: true,
      });
      setNewChatData({
        type: "text",
        text: dataObj.text,
        fromPrompt: false,
        timeStamp: new Date().getDate(),
        id: new Date().getDate().toString(),
      });
      setDataObj(initialData());
    }
  };

  useEffect(() => {
    if (queryResponse && queryResponse.status === "success") {
      setNewChatData({
        type: "text",
        text: queryResponse.data,
        fromPrompt: true,
        timeStamp: new Date().getDate(),
        id: new Date().getDate(),
      });
    }
  }, [queryResponse]);

  useEffect(() => {
    if (queryError) {
      if (queryError.status === 401) {
        setNewChatData();
      } else {
        setNewChatData({
          type: "text",
          text: `Something went wrong please try again.`,
          fromPrompt: true,
          timeStamp: new Date().getDate(),
          error: true,
          id: new Date().getTime(),
        });
      }
    }
  }, [queryError]);

  const handleDataChange = (key, value) => {
    setDataObj({ [key]: value, type: "text" });
  };

  const onSendButtonClick = () => {
    if (dataObj.text?.length) {
      if (!showChatLoader) {
        if (dataObj.text?.length === 0) return;
        handleSubmit();
      }
    } else {
      if (showRecordingHTML) {
        stopRecording(false);
        setSendAudio(true);
      } else {
        setSendAudio(false);
        startRecording();
      }
    }
  };

  useEffect(() => {
    if (blob && sendAudio) {
      setSendAudio(false);
      Utils.audioBlobToBase64(blob).then(
        (res) => {
          submitAudio(res);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }, [blob, sendAudio]);

  const submitAudio = (base64Object) => {
    if (base64Object) {
      const payload = {
        question: "",
        socketIOClientId: "",
        sessionId: "",
        botType: "voice",
        chatId: "d9c02cb4-1b01-4807-8f18-d5f99933efa7",
        uploads: [
          {
            type: "audio",
            name: `audio_${new Date().getTime()}.wav`,
            mime: blob.type,
            data: `data:${blob.type};codecs=opus;base64,${base64Object}`,
          },
        ],
      };
      processSpeech({
        payload,
        hideLoader: true,
        additionalHeaders: { "bot-id": params.botId },
      });
    }
  };

  useEffect(() => {
    if (speechResponse && speechResponse.text) {
      handleDataChange("text", speechResponse.text);
    }
  }, [speechResponse]);

  return (
    <div className="chat-container-wrapper">
      <div className="chat-container">
        <div className="chat-body-item">
          <ChatInteractions
            addNewChat={addNewChat}
            handleDataChange={(value) => handleDataChange("text", value)}
            botId={params.botId}
          />
        </div>
        <div className="chat-footer-item">
          <div className="footer-content">
            <div className="promptContainer">
              <div className="promptBox">
                <div className="prompt-btn-container">
                  <button
                    className={`prompt-btn ${
                      dataObj.text?.length === 0 && showRecordingHTML
                        ? "blackBackground"
                        : ""
                    }`}
                    onClick={onSendButtonClick}
                  >
                    {!showChatLoader && (
                      <div className={`sendButtonDiv`}>
                        {dataObj.text?.length === 0 && webRTCSupport ? (
                          <span>
                            {showRecordingHTML ? (
                              <i class="bi bi-check2"></i>
                            ) : (
                              <i class="bi bi-mic"></i>
                            )}
                          </span>
                        ) : (
                          <span
                            disabled={
                              showChatLoader || dataObj.text?.length === 0
                            }
                          >
                            <img
                              src="/assets/images/arrowLeft.svg"
                              alt="Send"
                            />
                          </span>
                        )}
                      </div>
                    )}

                    {showChatLoader && (
                      <div className="sendButtonDiv">
                        {" "}
                        <Lottie
                          style={{
                            width: "40px",
                            height: "40px",
                            background: "#f7f9fc",
                            borderRadius: "100%",
                          }}
                          animationData={LottieLoader}
                          loop={true}
                          autoplay={true}
                        />
                      </div>
                    )}
                  </button>
                </div>
                {showRecordingHTML ? (
                  <>
                    <div className={`audioAnimationPitch defaultMessageBox`}>
                      <div
                        className={`crossBtn`}
                        onClick={() => {
                          stopRecording(false);
                        }}
                      >
                        <i class="bi bi-x-lg"></i>
                      </div>

                      <AudioPitchAnimation
                        stream={stream}
                        isRecording={showRecordingHTML}
                      />
                    </div>
                  </>
                ) : (
                  <PromptBox
                    dataObj={dataObj}
                    onSendButtonClick={onSendButtonClick}
                    handleDataChange={(value) =>
                      handleDataChange("text", value)
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatBot;
