import { useEffect, useState, useRef } from "react";

const UseAudioRecorder = (timer = 30 * 1000) => {
    const [isRecording, setIsRecording] = useState(false);
    const [audioURL, setAudioURL] = useState("");
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const [blob, setBlob] = useState(null);
    const [stream, setStream] = useState();
    const [permissionStatus, setPermissionStatus] = useState();
    const timerRef = useRef();
    const [autoStopRecord, setAutoStopRecord] = useState();
    const [webRTCSupport, setWebRTCSupport] = useState(true);

    useEffect(() => {
        navigator.permissions.query({ name: "microphone" }).then((result) => {
            setPermissionStatus(result.state);
            result.onchange = () => {
                setPermissionStatus(result.state);
            };
        });
    }, []);

    const resetAudio = () => {
        setAudioURL(null);
        setBlob(null);
    };

    const startTimer = () => {
        timerRef.current = setTimeout(() => {
            stopRecording(true);
        }, timer);
    };

    const clearTimer = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
    };

    const startListening = (currentStream) => {
        if (currentStream) {
            mediaRecorderRef.current = new MediaRecorder(currentStream);
            mediaRecorderRef.current.start();
            setIsRecording(true);
            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunksRef.current.push(event.data);
                }
            };

            mediaRecorderRef.current.onstop = () => {
                const audioBlob = new Blob(audioChunksRef.current, {
                    type: "audio/wav",
                });
                setBlob(audioBlob);
                const audioURL = URL.createObjectURL(audioBlob);
                setAudioURL(audioURL);
                audioChunksRef.current = [];
                clearAllTracks();
            };
        }
    };

    const startRecording = async () => {
        clearTimer();
        setAutoStopRecord(false);
        if (!isRecording) {
            try {
                resetAudio();
                const currentStream = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                });
                startTimer();
                setStream(currentStream);
                startListening(currentStream);
            } catch (error) {
                console.error("Error accessing the microphone", error);
                setWebRTCSupport(false);
            }
        }
    };

    const clearAllTracks = () => {
        try {
            mediaRecorderRef.current.stream
                .getTracks()
                .forEach((track) => track.stop());
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (!isRecording && mediaRecorderRef && mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }
    }, [isRecording]);

    useEffect(() => {
        return () => {
            clearTimer();
        };
    }, []);

    const stopRecording = (autoStop) => {
        clearTimer();
        setAutoStopRecord(autoStop);
        setIsRecording(false);
    };

    return {
        isRecording,
        audioURL,
        startRecording,
        stopRecording,
        blob,
        stream,
        permissionStatus,
        autoStopRecord,
        webRTCSupport,
    };
};

export default UseAudioRecorder;
