import React, { useEffect, useState } from "react";

const AudioPlayer = ({ src, autoplay }) => {
  const [audioSrc, setAudioSrc] = useState(null);

  useEffect(() => {
    setAudioSrc(src);
  }, [src]);

  return (
    <>
      <audio controls src={audioSrc} autoPlay={autoplay}>
        Your browser does not support the audio element.
      </audio>
    </>
  );
};

export default AudioPlayer;
