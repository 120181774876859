import apiOptions from "./apiOptions";

const apiService = async (
  endPointDetails,
  payload = null,
  options = { type: "json" }
) => {
  try {
    const { finalUrl, requestOptions } = apiOptions(
      endPointDetails,
      payload,
      options
    );
    const response = await fetch(finalUrl, requestOptions);
    const contentType = response.headers.get("Content-Type");
    let data;
    if (contentType === "audio/wav" || contentType === "audio/aac") {
      const audioBlob = await response.blob();
      data = URL.createObjectURL(audioBlob);
    } else {
      data = await response.json();
    }
    if (!response.ok) {
      return {
        ok: false,
        message: data.message || JSON.stringify(data),
        status: response.status,
      };
    }
    return { ok: true, data };
  } catch (error) {
    console.log(error);
    console.error(error);
    return {
      ok: false,
      message:
        "I'm working on it to enable this feature, please visit after sometime!",
      error,
    };
  }
};

export default apiService;
