import { useState, useEffect, useRef } from "react";
import apiOptions from "../../services/apiOptions";

const useAudioStream = (
  audioRef,
  {
    endpoint,
    endpointKey = "default",
    payload = null,
    extendedUrl = "",
    additionalHeaders,
    options,
  }
) => {
  const mediaSourceRef = useRef(null);
  const sourceBufferRef = useRef(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const windowMediaError = "MediaSource API is not supported in your browser.";
  const apiError = { msg: "Error fetching audio stream:", error };

  useEffect(() => {
    if (audioRef && audioRef.current) {
      if (!window.MediaSource) {
        console.error(windowMediaError);
        setError(windowMediaError);
        return;
      }

      const mediaSource = new MediaSource();
      mediaSourceRef.current = mediaSource;
      audioRef.current.src = URL.createObjectURL(mediaSource);

      const onSourceOpen = async () => {
        try {
          let endPointDetails = {
            endpointName: endpoint,
            endpointKey,
            extendedUrl,
            additionalHeaders,
          };
          const { finalUrl, requestOptions } = apiOptions(
            endPointDetails,
            payload,
            options
          );
          setLoading(true);
          const response = await fetch(finalUrl, requestOptions);

          const contentType = response.headers.get("content-type");
          const sourceBuffer = mediaSource.addSourceBuffer(contentType);
          sourceBufferRef.current = sourceBuffer;

          const reader = response.body.getReader();

          const appendNextChunk = async () => {
            const { done, value } = await reader.read();
            if (done) {
              mediaSource.endOfStream();
              setLoading(false);
              return;
            }
            sourceBuffer.appendBuffer(value);

            if (sourceBuffer.updating) {
              sourceBuffer.addEventListener("updateend", appendNextChunk, {
                once: true,
              });
            } else {
              appendNextChunk();
            }
          };
          appendNextChunk();
        } catch (error) {
          setLoading(false);
          setError(apiError);
          console.error(apiError);
        }
      };

      mediaSource.addEventListener("sourceopen", onSourceOpen);

      return () => {
        mediaSource.removeEventListener("sourceopen", onSourceOpen);
        if (audioRef?.current?.src) {
          URL.revokeObjectURL(audioRef.current.src);
        }
      };
    }
  }, []);

  return { audioRef, loading, error };
};

export default useAudioStream;
