"use client";
import { createContext, useContext, useState, useEffect } from "react";
import Utils from "../services/Utils";

const RootContext = createContext();

export const RootProvider = ({ children }) => {
  const [showLoader, setShowLoader] = useState(0);
  const [userInfo, setUserInfo] = useState(null);
  const [defaultText, setDefaultText] = useState(null);
  const [uiConfig, setUiConfig] = useState(null);

  useEffect(() => {
    const data = Utils.getStorage("userInfo");
    setUserInfo(data);
  }, []);

  useEffect(() => {
    if (userInfo !== null) {
      Utils.setStorage("userInfo", userInfo);
    }
  }, [userInfo]);

  const updateUserInfo = (data) => {
    Utils.setStorage("userInfo", data);
    setUserInfo(data);
  };

  return (
    <RootContext.Provider
      value={{
        showLoader,
        setShowLoader,
        userInfo,
        setUserInfo,
        updateUserInfo,
        setDefaultText,
        defaultText,
        setUiConfig,
        uiConfig,
      }}
    >
      {children}
    </RootContext.Provider>
  );
};

export const useRootContext = () => {
  return useContext(RootContext);
};
