import React, { useRef } from "react";
import useAudioStream from "./useAudioStream";

const StreamingAudioPlayer = ({
  endpoint,
  endpointKey,
  payload,
  extendedUrl,
  additionalHeaders,
  options,
}) => {
  const audioRef = useRef();
  const { audioElement, loading, error } = useAudioStream(audioRef, {
    endpoint,
    endpointKey,
    payload,
    extendedUrl,
    additionalHeaders,
    options,
  });

  return (
    <>
      {error && <div className="error"> </div>}
      <audio ref={audioRef} controls={true} autoPlay={true}></audio>
      {loading && <i className="bi bi-arrow-clockwise rotate"></i>}
    </>
  );
};

export default StreamingAudioPlayer;
