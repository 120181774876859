import React, { useEffect, useState } from "react";
import "./NavBar.scss";
import NavList from "./NavList";
import { NavLink } from "react-router-dom";
import { useRootContext } from "../../context/context";

function LayoutNavBar() {
  const handleMenuClick = (event) => {};
  const { defaultText, userInfo } = useRootContext();
  const [currentNavList, setCurrentNavList] = useState([]);

  const pushDefaultList = () => {
    const data = [
      {
        title: userInfo?.username,
        path: "/login",
        subMenu: [
          {
            title: "Logout",
            path: "/login",
          },
        ],
      },
    ];
    setCurrentNavList(() => [...NavList, ...data]);
  };

  useEffect(() => {
    // pushDefaultList();
  }, [userInfo]);

  return (
    <nav className="navbar NavBar navbar-expand-sm">
      <div className="container-fluid">
        <NavLink className={() => `navbar-brand bs-title`} to={`/`}>
          {defaultText?.navbarBrandTitle}
        </NavLink>
        {/* <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button> */}
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNavDropdown"
        >
          <ul className="navbar-nav">
            {currentNavList.map((ele, index) => {
              return ele.subMenu && ele.subMenu ? (
                <li
                  className="nav-item dropdown"
                  key={ele.path || ele.action || index}
                >
                  <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                      <span
                        className="nav-link dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        {ele.title}
                      </span>
                      <ul className="dropdown-menu dropdown-menu-lg-end">
                        {ele.subMenu.map((subMenu, subIndex) => (
                          <li key={subMenu.path || subMenu.action || subIndex}>
                            <span className="dropdown-item">
                              {subMenu.path ? (
                                <NavLink
                                  className={({ isActive }) =>
                                    `nav-link ${isActive ? "active" : "none"}`
                                  }
                                  to={`${subMenu.path}`}
                                >
                                  {subMenu.title}
                                </NavLink>
                              ) : (
                                <span
                                  className="nav-link"
                                  onClick={() =>
                                    handleMenuClick({
                                      ele,
                                      subMenu,
                                      action: subMenu.action,
                                    })
                                  }
                                >
                                  {subMenu.title}
                                </span>
                              )}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                </li>
              ) : (
                <li className="nav-item" key={`${ele.path || ele.action}`}>
                  {ele.path ? (
                    <NavLink
                      className={({ isActive }) =>
                        `nav-link ${isActive ? "active" : ""}`
                      }
                      to={`${ele.path}`}
                    >
                      {ele.title}
                    </NavLink>
                  ) : (
                    <span
                      className="nav-link"
                      onClick={() =>
                        handleMenuClick({ ele, action: ele.action })
                      }
                    >
                      {ele.title}
                    </span>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default LayoutNavBar;
