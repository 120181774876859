import React, { useEffect, useState } from "react";
import Utils from "../services/Utils";

function FormFieldsValidation(formRef) {
  const [errorFields, setErrorFields] = useState({});

  const fieldValidation = (event, externalEvent = false) => {
    let input = event;
    if (!externalEvent) {
      const selectedNameAttribute = formRef.current
        .querySelectorAll(`textarea[name="${event.target.name}"], 
                input[name="${event.target.name}"],
                 select[name="${event.target.name}"]`);
      input = selectedNameAttribute[0];
    }
    if (input) {
      const newErrors = Utils.validateFormField(
        { input, fields: errorFields },
        externalEvent
      );
      setErrorFields(newErrors.fields);
    }
  };
  const changeErrorFields = (data) => {
    setErrorFields(data);
  };

  const isFormValid = () => {
    const { flag, fields } = Utils.validForm(formRef);
    setErrorFields(fields);
    return flag;
  };

  return { fieldValidation, isFormValid, changeErrorFields };
}

export default FormFieldsValidation;
