import React, { useEffect, useRef, useState } from "react";
import "./login.scss";
import API_Call from "../../services/apiCall";
import CustomSelect from "../../common-components/custom-select/CustomSelect";
import { useNavigate } from "react-router-dom";
import Utils from "../../services/Utils";
import { useRootContext } from "../../context/context";

function Signup() {
  const [payLoad, setPayload] = useState({
    username: "",
    password: "",
    email: "",
    permissions: "ADMIN",
    tenantType: "INDIVIDUAL",
    tenantName: "",
  });

  const [confirmPasword, setConfirmPassword] = useState({});
  const [errorFields, setErrorFields] = useState({});
  const navigate = useNavigate();
  const submitForm = useRef();
  const [isTenantOnboard, setIsTenantOnBoard] = useState(false);
  const [tenantList, setTenantList] = useState([]);
  const { defaultText } = useRootContext();

  const {
    response: signupResponse,
    loading: signupLoading,
    error: signupError,
    fetchData: processSignup,
  } = API_Call({
    endpoint: "signup",
    endpointKey: "UNIFIED_SERVICE",
  });

  const {
    response: tenantListResponse,
    loading: tenantListLoading,
    error: tenantListError,
    fetchData: getTenantList,
  } = API_Call({
    endpoint: "tenantList",
    endpointKey: "UNIFIED_SERVICE",
  });

  const {
    response: tenantOnBoardResponse,
    loading: tenantOnBoardLoading,
    error: tenantOnBoardError,
    fetchData: processTenantOnBoard,
  } = API_Call({
    endpoint: "tenantOnBoard",
    endpointKey: "UNIFIED_SERVICE",
  });

  useEffect(() => {
    getTenantList({});
  }, []);

  useEffect(() => {
    if (tenantListResponse) {
      if (tenantListResponse && tenantListResponse.length) {
        const tempList = tenantListResponse.map((ele) => {
          return { value: ele.tenantId, label: ele.tenantName };
        });
        setTenantList(tempList);
      }
    }
  }, [tenantListResponse]);

  const onInputChange = (event) => {
    const temp = { ...payLoad };
    temp[event.target.name] = event.target.value;
    setPayload(temp);
    const type = event.target.getAttribute("validatortype");
    if (Utils.formValidation[type](event.target.value)) {
      setErrorFields((data) => {
        return {
          ...data,
          [event.target.name]: "",
        };
      });
    }
  };

  const validForm = () => {
    let flag = true;
    const temp = {};
    Array.from(submitForm.current.elements).forEach((input) => {
      const type = input.getAttribute("validatortype");
      if (type) {
        if (!Utils.formValidation[type](input.value)) {
          flag = false;
          temp[input.name] = `Enter Valid ${input.getAttribute("label")}`;
        } else {
          temp[input.name] = null;
        }
      }
    });
    setErrorFields(temp);
    if (payLoad.password !== confirmPasword) {
      flag = false;
    }
    return flag;
  };

  const handleSingup = (e) => {
    e.preventDefault();
    if (validForm()) {
      if (isTenantOnboard) {
        processTenantOnBoard({ payload: payLoad });
      } else {
        if (payLoad.tenantId) {
          processSignup({ payload: payLoad, extendedUrl: payLoad.tenantId });
        }
      }
    }
  };

  useEffect(() => {
    if (signupResponse || tenantOnBoardResponse) {
      if (
        signupResponse?.username ||
        tenantOnBoardResponse?.status === "success"
      ) {
        navigate("/login");
      }
    }
  }, [signupResponse, tenantOnBoardResponse]);

  const navigateToLogin = () => {
    navigate("/login");
  };

  useEffect(() => {}, [signupError]);

  const changeTenantType = (event) => {
    setPayload((data) => {
      return { ...data, tenantId: event };
    });
  };

  useEffect(() => {
    // console.log(payLoad);
  }, [payLoad]);

  return (
    <div className="container login-container full-page">
      <div className="center-container">
        <div className="login-title">
          <h5 className="">{defaultText?.welcomeText}</h5>
          <div className="logo"> {/* keep a user icon herer */}</div>
        </div>
        <form onSubmit={handleSingup} ref={submitForm} noValidate>
          <div className="card animate__animated animate__fadeIn">
            <div className="form-group">
              <label>User Name</label>
              <input
                type="text"
                autocomplete="off"
                className="form-control"
                placeholder="e.g John"
                name="username"
                onChange={onInputChange}
                value={payLoad.username}
                validatortype="name"
                label="Name"
              />
              {errorFields.username && (
                <p className="error-field">{errorFields.username}</p>
              )}
            </div>

            <div className="row">
              <div className="col-md-10">
                {isTenantOnboard && (
                  <div className="form-group">
                    <label>Tenant Name</label>
                    <input
                      type="text"
                      autocomplete="off"
                      className="form-control"
                      placeholder="e.g OLA Electric"
                      name="tenantName"
                      onChange={onInputChange}
                      value={payLoad.tenantName}
                      validatortype="name"
                      label="Tenant Name"
                    />
                    {errorFields.tenantName && (
                      <p className="error-field">{errorFields.tenantName}</p>
                    )}
                  </div>
                )}
                {!isTenantOnboard && (
                  <div className="form-group">
                    <label>Choose Tenant</label>
                    <CustomSelect
                      value={payLoad.tenantId}
                      onOptionChange={changeTenantType}
                      options={tenantList}
                    />
                    {errorFields.tenantId && (
                      <p className="error-field">{errorFields.tenantId}</p>
                    )}
                  </div>
                )}
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label>New</label>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      autocomplete="off"
                      value={isTenantOnboard}
                      type="checkbox"
                      role="switch"
                      onChange={(e) => setIsTenantOnBoard((flag) => !flag)}
                      checked={isTenantOnboard}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                autocomplete="off"
                placeholder="Enter Email"
                name="email"
                onChange={onInputChange}
                validatortype="email"
                value={payLoad.email}
                label="Email"
              />
              {errorFields.email && (
                <p className="error-field">{errorFields.email}</p>
              )}
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                name="password"
                autocomplete="off"
                onChange={onInputChange}
                value={payLoad.password}
                validatortype="password"
                label="Password"
              />
              {errorFields.password && (
                <p className="error-field">{errorFields.password}</p>
              )}
            </div>
            <div className="form-group">
              <label>Confirm Password</label>
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                name="password"
                autocomplete="off"
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPasword}
              />
              {payLoad?.password &&
                confirmPasword.length > 0 &&
                payLoad?.password !== confirmPasword && (
                  <p className="error-field">Passwords Not Matching</p>
                )}
            </div>
            <button type="submit" className="btn btn-primary btn-sm">
              Signup
            </button>
          </div>
        </form>
        <div className="signup-footer">
          Click Here to{" "}
          <button className="btn btn-sm btn-primary" onClick={navigateToLogin}>
            Login
          </button>
        </div>
      </div>
    </div>
  );
}

export default Signup;
