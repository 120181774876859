import React, { useState, useEffect } from "react";
import "./Portfolio.scss";
import TypeWriter from "../../common-components/type-writer/TypeWriter";
import { useNavigate } from "react-router-dom";
import CardsSlider from "../../common-components/cards-slider/CardsSlider";
import API_Call from "../../services/apiCall";
import Faqs from "../Pages/Profile/FAQs/Faqs";
import ProjectsInfo from "./ProjectsInfo/ProjectsInfo";
import InfiniteItems from "../../common-components/InfiniteItems/InfiniteItems";
import Utils from "../../services/Utils";

const faqsMapping = [
  { key: "FAQ_Web", title: "Web Engineering" },
  { key: "FAQ_SE", title: "Software Engineering" },
  { key: "FAQ_Academics", title: "Academics" },
];
const description = ` Meet Yaseen, a dynamic Senior Front End Lead Developer with over 7 years of expertise in crafting innovative web applications. Passionate about leveraging cutting-edge technologies, he excels in creating seamless user experiences while driving team collaboration and project success. His journey reflects a commitment to excellence and a knack for transforming ideas into high-performance digital and AI solutions.`;
const s3Path = "/assets";

function Portfolio() {
  const [profileData, setProfileData] = useState({});
  const [visitorsCount, setVisitorsCount] = useState(4030);

  const navigate = useNavigate();
  const AboutCaption = () => {
    return (
      <div className="left-content">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <TypeWriter text={description} className="description-section" />
          </div>
          <div className="col-md-6">
            <div className="paint-bg">
              <img src="/assets/images/fe-tech.png" width={"100%"} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const openChatBot = () => {
    navigate("/ai-bot");
  };

  const {
    response: profileDataResponse,
    error: profileDataError,
    fetchData: fetchProfileData,
  } = API_Call({ endpoint: "profileData" });

  const {
    response: addVisitorResponse,
    loading: addVisitorLoading,
    error: addVisitorError,
    fetchData: processAddVisitor,
  } = API_Call({ endpoint: "addVisitor", endpointKey: "AI_SERVICES" });

  const onLoadFetch = () => {
    fetchProfileData({});
  };
  useEffect(() => {
    if (profileDataResponse && profileDataResponse.status === "success") {
      setProfileData(profileDataResponse.data);
    }
  }, [profileDataResponse]);

  const checkVisitedStatus = () => {
    if (Utils.getStorage("visitorCount")) {
      processAddVisitor({
        hideLoader: true,
        payload: { increment: false },
        hideToaster: true,
      });
    } else {
      processAddVisitor({
        hideLoader: true,
        payload: { increment: true },
        hideToaster: true,
      });
    }
  };

  useEffect(() => {
    onLoadFetch({});
    // checkVisitedStatus();
  }, []);

  useEffect(() => {
    if (
      addVisitorResponse &&
      addVisitorResponse.status === "success" &&
      addVisitorResponse.data
    ) {
      setVisitorsCount(addVisitorResponse.data.homePageVisitors);
      Utils.setStorage(
        "visitorCount",
        addVisitorResponse.data.homePageVisitors
      );
    }
  }, [addVisitorResponse]);

  return (
    <div className="profile-wrapper">
      <div className="stars"></div>
      <div className="twinkling"></div>
      <div className="">
        <header className="hero-section">
          <div className="hero-section-child">
            <div className="row form-group">
              <div className="col-md-6">
                <div className="title-section">
                  <h1>
                    <TypeWriter
                      text={"Mohammed Yaseen"}
                      className={"title-gradient-font"}
                      speed={120}
                    />
                  </h1>
                  <p>Senior Front-End Developer / Lead | AI Enthusiast</p>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                {/* <div className="col-12">
                                    <button
                                        className="gradient-button ask-assistant-btn"
                                        onClick={openChatBot}
                                    >
                                        Ask My Assistant <i className="bi bi-robot"></i>
                                    </button>
                                </div> */}
                <div>
                  <a href="tel:+917416557472">
                    <div>Phone: +91 741 6557472</div>
                  </a>
                  <p>Email: yaseen.uideveloper@gmail.com</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-4">
                      <a
                        className="gradient-button gradient-button-sm"
                        target="_blank"
                        href="https://www.linkedin.com/in/yaseen-ui/"
                      >
                        <i className="bi bi-linkedin"></i>
                      </a>
                    </div>
                    <div className="col-4">
                      <a
                        className="gradient-button gradient-button-sm"
                        target="_blank"
                        href="https://github.com/yaseen-ui"
                      >
                        <i className="bi bi-github"></i>
                      </a>
                    </div>
                    <div className="col-4">
                      <a
                        className="gradient-button gradient-button-sm"
                        target="_blank"
                        href={`${s3Path}/my_resume.pdf`}
                      >
                        <i className="bi bi-link-45deg"></i> CV
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <section id="about" className="mobile-about-caption">
          <div className="container">
            <AboutCaption />
          </div>
        </section>

        <section id="experience" className="py-5">
          <div className="container">
            <h2 className="text-center mb-4">Work Experience</h2>
            <div className="row">
              <CardsSlider cardsData={profileData.experience} />
            </div>
          </div>
        </section>

        <section id="skills" className="py-5">
          <div className="container">
            <h2 className="text-center mb-4">My Skillsets</h2>
            <InfiniteItems items={profileData.techStacks} />
          </div>
        </section>

        <section id="projects" className="py-5">
          <div className="container">
            <h2 className="text-center mb-4">My Recent Projects</h2>
            <ProjectsInfo data={profileData.projects} />
          </div>
        </section>

        <section id="faqs" className="py-5">
          <div className="container">
            <h2 className="text-center mb-4">Frequently Asked Question</h2>
            {profileData && (
              <div>
                {/* Faqs */}
                <div className="mb-2" id="faqs_id">
                  {faqsMapping.map((ele) => (
                    <div className="mt-2" key={`faq_${ele.key}`}>
                      <Faqs
                        id={ele.key}
                        data={profileData[ele.key]}
                        title={ele.title}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </section>

        <footer>
          <div className="container">
            <p>&copy; 2024 Mohammed Yaseen. All Rights Reserved.</p>
            <p>Phone: +91 7416557472</p>
            <p> Email: yaseen.uideveloper@gmail.com</p>
            {/* <p>{`Profile Visitor (${visitorsCount})`}</p> */}
          </div>
        </footer>
      </div>
    </div>
  );
}

export default Portfolio;
