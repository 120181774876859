import React, { useEffect, useState, useRef } from "react";
import "./login.scss";
import API_Call from "../../services/apiCall";
import { useRootContext } from "../../context/context";
import { useNavigate } from "react-router-dom";
import Utils from "../../services/Utils";
import FormFieldsValidation from "../../common-components/FormFieldsValidation";

function Login() {
  const [payLoad, setPayload] = useState({ email: "", password: "" });
  const { updateUserInfo, userInfo, defaultText } = useRootContext();

  const formRef = useRef();

  const { fieldValidation, isFormValid } = FormFieldsValidation(formRef);

  const navigate = useNavigate();
  const {
    response: loginResponse,
    loading: loginLoading,
    error: loginError,
    fetchData: processlogin,
  } = API_Call({ endpoint: "login", endpointKey: "UNIFIED_SERVICE" });

  const onInputChange = (event) => {
    fieldValidation(event);
    const temp = { ...payLoad };
    temp[event.target.name] = event.target.value;
    setPayload(temp);
  };
  useEffect(() => {
    Utils.setStorage("userInfo", null);
    updateUserInfo(null);
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    if (isFormValid()) {
      processlogin({ payload: payLoad });
    }
  };

  const navigateToSignup = () => {
    navigate("/signup");
  };

  useEffect(() => {
    if (loginResponse) {
      if (loginResponse.status === "success") {
        updateUserInfo(loginResponse.data);
      }
    }
  }, [loginResponse]);

  useEffect(() => {
    const userInfoFromLocalStorage = Utils.getStorage("userInfo");
    if (
      userInfo &&
      userInfo.tenantId &&
      userInfoFromLocalStorage &&
      userInfoFromLocalStorage.tenantId
    ) {
      navigate("/");
    }
  }, [userInfo]);

  useEffect(() => {}, [loginError]);

  return (
    <div className="container login-container full-page">
      <div className="center-container">
        <div className="login-title">
          <h5 className="">{defaultText?.welcomeText}</h5>
          <div className="logo"> {/* keep a user icon herer */}</div>
        </div>
        <form onSubmit={handleLogin} ref={formRef} noValidate>
          <div className="card">
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter Your Email"
                name="email"
                onChange={onInputChange}
                value={payLoad.email}
                label="Email"
                validatortype="email"
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter Your Password"
                name="password"
                onChange={onInputChange}
                value={payLoad.password}
                label="Password"
                validatortype="password"
              />
            </div>
            <button className="btn btn-primary btn-sm" type="submit">
              Login
            </button>
          </div>
        </form>
        <div className="signup-footer">
          Click Here to{" "}
          <button className="btn btn-sm btn-primary" onClick={navigateToSignup}>
            Signup
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
