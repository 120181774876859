import React from 'react'

function Faqs({data=[], title, id}) {
  return (
    <div className="container mt-5">
  <h2 className="mb-4">{title}</h2>
  <div className="accordion" id={id}>
    {
      data && data.map((ele,index)=><div className="accordion-item" key={index}>
        <h2 className="accordion-header">
          <button className="accordion-button" type="button" data-bs-toggle="collapse"  data-bs-target={`#${id}_${index}`}>
            {ele.question}
          </button>
        </h2>
        <div id={`${id}_${index}`} className={`accordion-collapse collapse ${!index ? 'show': ''}`} data-bs-parent={`#${id}`}>
          <div className="accordion-body" dangerouslySetInnerHTML={{ __html: ele.answer }}>
          </div>
        </div>
      </div>)
    }
  </div>
</div>
  )
}

export default Faqs