import React from "react";
import "./commonLoader.scss";
import Lottie from "lottie-react";
import loader from "./../../common-components/lottie-files/loading.json";

function CommonLoader() {
  return (
    <div className="loader-conainer">
      <div className="loader-fade"></div>
      <div className="spinner">
        <Lottie
          className={`yash-ui-spinner`}
          animationData={loader}
          autoPlay={true}
          loop={true}
        />
      </div>
    </div>
  );
}

export default CommonLoader;
